@charset "UTF-8";
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body,
html {
  overflow: auto;
}
* {
  margin: 0;
  padding: 0;
}
* {
  font-family: Roboto, sans-serif;
}
p {
  margin: 0 !important;
  padding: 0 !important;
}
.align-items-center {
  align-items: center;
}
html {
  --scrollbarBG: #ebf4f8;
  --thumbBG: #e2e2e2;
}
.catalog-collapse {
  border: none !important;
}
.catalog-collapse.ant-collapse-item {
  border: none !important;
}
.catalog-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: none !important;
}
.catalog-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
  background: #fff !important;
}
.catalog-collapse .ant-collapse-content-box {
  padding-left: 0 !important;
}
.product-collapse {
  padding: 0 5px !important;
  border: none !important;
  border-bottom: 1px solid #dcdcdc !important;
}
.product-collapse.ant-collapse-item {
  border: none !important;
}
.product-collapse .ant-collapse-arrow {
  left: 97% !important;
}
.product-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
  background: #fff !important;
}
.product-collapse-border {
  border-radius: 0 !important;
  padding-left: 15px !important;
  border: 1px solid #dcdcdc !important;
  border-bottom: 1px solid #dcdcdc !important;
}
.product-collapse-border.ant-collapse-item {
  border: none !important;
}
.product-collapse-border .ant-collapse-arrow {
  left: 97% !important;
}
.product-collapse-border.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
  background: #fff !important;
}
.account-collapse {
  border: none !important;
}
.account-collapse.ant-collapse {
  background: none;
}
.account-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 92% !important;
}
.account-collapse.ant-collapse > .ant-collapse-item {
  border-bottom: 0;
  border: 1px solid #dcdcdc;
  border-radius: 0 !important;
}
.account-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 18px 12px !important;
  background: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 0 !important;
}
.account-collapse.account-collapse.ant-collapse > .ant-collapse-item {
  border: none;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.ant-collapse-content {
  border-top: none !important;
}
.ant-divider .ant-divider-horizontal {
  background-color: #d2d2d7 !important;
}
.ant-divider {
  margin: 5px 0 !important;
}
.ant-drawer-mask {
  display: none;
}
.ant-drawer-content-wrapper {
  margin-top: 62px;
}
.ant-drawer-wrapper-body {
  overflow-x: hidden !important;
}
.drawer-90 .ant-drawer-content-wrapper {
  width: 90% !important;
}
.ant-drawer-close {
  background: #fff !important;
  width: 100% !important;
  text-align: right !important;
  padding-right: 39px !important;
}
.ant-select-dropdown-menu-vertical {
  border-radius: 0 !important;
}
.ant-menu-submenu-title {
  padding: 0 !important;
  height: 30px;
  line-height: 30px;
  color: #444343;
  padding-right: 50px !important;
}
.ant-menu-submenu-title.ant-menu-submenu-title:hover {
  color: #444343;
}
.ant-menu-submenu-title .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: #444343 !important;
}
.ant-menu-submenu-title.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #444343 !important;
}
.ant-menu-item {
  padding: 0 !important;
}
.ant-menu-item.ant-menu-item-selected {
  background: transparent !important;
  font-weight: bold;
}
.ant-menu p {
  color: #444343;
}
.ant-menu-inline {
  border: 0px !important;
}
.ant-menu-inline .ant-menu-item::after {
  display: none;
}
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: #444343 !important;
}
.test p {
  color: red !important ;
}
.ant-dropdown-menu {
  border-radius: 0 !important;
  border: 1px solid #dcdcdc !important;
}
.icon .anticon {
  color: #fff !important;
}
.ant-select-arrow-icon svg {
  color: black;
  width: 10px !important;
}
.ant-input {
  border-radius: 0 !important;
}
.ant-form-item {
  margin-bottom: 20px !important;
}
.input-fat .ant-input {
  height: 50px;
}
.transparent-input .ant-input {
  background: transparent !important;
}
.ant-checkbox-wrapper {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}
.ant-menu-submenu {
  padding-right: 20px;
}
.cart-drawer .ant-drawer-title {
  font-weight: normal;
  font-size: 15px;
}
.ant-drawer-body {
  padding-right: 5px !important;
  padding-top: 13px !important;
  padding-left: 0px !important;
}
.ant-menu-submenu {
  border-bottom: 1px solid #dcdcdc;
}
.ant-menu-sub.ant-menu-inline {
  margin-left: 20px !important;
}
.inner-sub .ant-menu-sub.ant-menu-inline {
  margin: 0 !important;
}
.ant-select {
  width: 100% !important;
  border: none;
  outline: none;
}
.transparent-select .ant-select-selection {
  border: 0;
}
.transparent-select .ant-select-selection:focus {
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none;
}
.bordered-select .ant-select-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  height: 50px;
  font-size: 13px;
  color: #444343;
}
.bordered-select .ant-select-selection:focus {
  border: 1px solid #679964;
  border-radius: 0 !important;
  box-shadow: none;
}
.select-text-center .ant-select-selection__rendered {
  margin: 0 auto !important;
}
.ant-btn {
  height: 100% !important;
}
.transparent-btn {
  border: 0 !important;
  border-radius: 0 !important;
  background: #fff !important;
  width: 100% !important;
  height: 35px !important;
  font-size: 13px !important;
  font-weight: bold !important;
}
.transparent-btn:hover,
.transparent-btnfocus {
  background: #679964 !important;
  color: #fff !important;
}
.delete-orange-btn {
  background-color: #f89d04 !important;
  border-radius: 0 !important;
  width: 100%;
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-orange-btn i {
  font-size: 15px;
  color: #fff;
}
.orange-btn {
  width: 100% !important;
  height: 50px !important;
  border-radius: 0 !important;
  background: #f89d04 !important;
  color: #fff !important;
  font-weight: bold !important;
}
.transparent-btn-fat {
  border: 0 !important;
  border-radius: 0 !important;
  background: #fff !important;
  width: 100% !important;
  height: 50px !important;
  font-size: 13px !important;
  font-weight: bold !important;
}
.transparent-btn-fat:hover,
.transparent-btn-fatfocus {
  background: #679964 !important;
  color: #fff !important;
}
.dark-btn {
  border: none !important;
  border-radius: 0 !important;
  background: #444343 !important;
  width: 100% !important;
  height: 35px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  color: #fff !important;
}
.dark-btn:hover,
.dark-btnfocus {
  background: #679964 !important;
  color: #fff !important;
}
.dark-btn-fat {
  border: none !important;
  border-radius: 0 !important;
  background: #444343 !important;
  width: 100% !important;
  height: 50px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: #fff !important;
}
.dark-btn-fat:hover,
.dark-btn-fatfocus {
  background: #679964 !important;
  color: #fff !important;
}
.dark-btn-fat-checkout {
  border: none !important;
  border-radius: 0 !important;
  background: #444343 !important;
  width: 50% !important;
  height: 50px !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: #fff !important;
}
.dark-btn-fat-checkout.dark-btn-fat-checkout:disabled {
  opacity: 0.4;
}
.dark-btn-fat-checkout.dark-btn-fat-checkout:disabled:hover {
  opacity: 0.4;
}
.dark-btn-fat-checkout:hover {
  opacity: 0.9;
}
.btn-heart i {
  font-size: 20px !important;
}
.ant-tabs-top-bar {
  border-bottom: 0 !important;
}
.ant-tabs-nav-container-scrolling {
  padding: 0;
}
.ant-tabs-nav .ant-tabs-tab {
  margin: 10px;
  padding: 0;
  border: none;
  padding: 2px 0px !important;
  margin: 0 20px 1px 0 !important;
}
.ant-tabs-tab-active {
  border: 2px solid #679964 !important;
  transition: 0.5s ease-in !important;
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  left: 40% !important;
}
.ant-tabs-tab-prev-icon svg,
.ant-tabs-tab-next-icon svg {
  color: #dcdcdc !important;
}
.ant-tabs-ink-bar {
  display: none !important;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  display: block;
}
.ant-tabs-tab-next {
  right: -12px;
}
.ant-tabs-tab-prev {
  left: -12px;
}
.hover:hover {
  cursor: pointer;
}
.text-right {
  text-align: right;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-unstyled li {
  margin: 5px 0 5px 0;
  /*  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 15px; */
}
.margin-center {
  margin: 0 auto !important;
}
.img-fluid {
  width: 100%;
  height: auto;
}
.img-fluid-product {
  width: 100%;
  height: auto;
}
.img-fluid-inv {
  height: 100% !important;
  width: auto !important;
}
.img-fluid-slider {
  width: 100%;
  object-fit: contain;
  height: auto;
}
.d-flex-start {
  display: flex;
  justify-content: flex-start;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mx-0 {
  margin: 0;
}
.pl-0 {
  padding-left: 0 !important;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  padding-top: 1.5rem;
}
.pt-5 {
  padding-top: 3rem;
}
.pt-15 {
  padding-top: 15px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 1rem;
}
.pb-4 {
  padding-bottom: 1.5rem;
}
.pb-5 {
  padding-bottom: 3rem;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 1rem;
}
.pr-4 {
  padding-right: 1.5rem;
}
.pr-5 {
  padding-right: 3rem;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 1rem;
}
.pl-4 {
  padding-left: 1.5rem;
}
.pl-5 {
  padding-left: 3rem;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 1rem;
}
.p-4 {
  padding: 1.5rem;
}
.p-5 {
  padding: 3rem;
}
.mt-3 {
  margin-top: 3px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-33 {
  margin-top: 33px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-250 {
  margin-top: 250px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mb-5 {
  margin-bottom: 3rem;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-70 {
  margin-left: 70px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-100 {
  margin-left: 100px;
}
.mr-05 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-70 {
  margin-right: 70px;
}
.mr-80 {
  margin-right: 80px;
}
.mr-90 {
  margin-right: 90px;
}
.mr-100 {
  margin-right: 100px;
}
.width-50 {
  width: 50px;
}
.width-70 {
  width: 70px;
}
.width-90 {
  width: 90px;
}
.width-100 {
  width: 100px;
}
.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-250 {
  width: 250px;
}
.width-300 {
  width: 300px;
}
.width-full {
  width: 100% !important;
}
table {
  width: 100% !important;
}
.d-flex {
  display: flex !important;
}
.d-flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-star {
  justify-content: flex-start;
}
.last-item {
  margin-left: auto;
}
@media (max-width: 576px) {
  .mt-10-mobile {
    margin-top: 10px;
  }
  .mb-10-mobile {
    margin-bottom: 10px !important;
  }
}
@media (min-width: 1200px) {
  .ml-40-desktop {
    margin-left: 40px !important;
  }
}
@media (max-width: 1200px) {
  .mt-20-mobile {
    margin-top: 20px !important;
  }
}
.position-relative {
  position: relative !important;
}
.zIndex2 {
  z-index: 2 !important;
}
.break-spaces {
  white-space: break-spaces;
}
.dark-btn {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: #444343 !important;
  height: 35px !important;
  width: 100% !important;
}
.dark-btn.dark-btn span {
  color: #ffffff !important;
}
.primary-btn {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: #679964 !important;
  height: 35px !important;
  width: 100% !important;
}
.primary-btn.primary-btn span {
  color: #fff !important;
}
.bordered-button {
  border: solid 1px #a9a9a9;
  border-radius: 0 !important;
  background-color: transparent !important;
  height: 50px !important;
  width: 100% !important;
}
.outlined-button {
  border: solid 1px #a9a9a9;
  border-radius: 0 !important;
  background-color: transparent !important;
  height: 35px !important;
  width: 230px !important;
}
.outlined-button.primary-btn span {
  color: #fff !important;
}
.outlined-button-mobile {
  border: solid 1px #a9a9a9;
  border-radius: 0 !important;
  background-color: transparent !important;
  height: 50px !important;
  width: 100% !important;
}
.outlined-button-mobile.primary-btn span {
  color: #fff !important;
}
.outlined-button-mobile-icon {
  border: solid 1px #a9a9a9;
  border-radius: 0 !important;
  background-color: transparent !important;
  height: 50px !important;
  width: 100% !important;
}
.outlined-button-mobile-icon.primary-btn span {
  color: #fff !important;
}
.outlined-button-product-icon {
  border: solid 1px #a9a9a9;
  border-radius: 0 !important;
  background-color: transparent !important;
  height: 36px !important;
  width: 46px !important;
  padding: 0 11px !important;
  color: #a9a9a9 !important;
}
.outlined-button-single-product-icon {
  border: solid 1px #a9a9a9;
  border-radius: 0 !important;
  background-color: transparent !important;
  height: 50px !important;
  width: 50px !important;
  color: #a9a9a9 !important;
}
.cart-button-product-icon {
  border-radius: 0 !important;
  border: 0 !important;
  background-color: #444343 !important;
  height: 36px !important;
  width: 46px !important;
  padding: 0 8px !important;
  color: #fff !important;
}
.home-btn {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: #679964;
  height: 50px !important;
  width: 100% !important;
}
.home-btn.primary-btn-fat span {
  color: #fff !important;
}
.primary-btn-fat {
  border: 0 !important;
  border-radius: 0 !important;
  background-color: #679964 !important;
  height: 50px !important;
  width: 100% !important;
}
.primary-btn-fat.primary-btn-fat span {
  color: #fff !important;
}
.light-btn {
  border: 1px solid #dcdcdc !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  height: 35px !important;
  width: 100% !important;
}
.light-btn .light-btn span {
  color: #444343 !important;
}
.light-btn-fat {
  border: 1px solid #679964 !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  height: 50px !important;
  width: 100% !important;
}
.light-btn-fat .light-btn-fat span {
  color: #444343 !important;
}
.edit-button {
  font-size: 20px;
}
.edit-button:hover {
  opacity: 0.7;
}
.delete-button {
  font-size: 20px;
}
.delete-button:hover {
  opacity: 0.6;
}
.ant-checkbox-group div {
  margin-bottom: 5px;
}
.ant-radio-group div {
  margin-bottom: 5px;
}
.info-filters-desktop {
  display: block;
}
.mobile-filters-container {
  width: 100%;
}
.catalog-container {
  margin-top: 20px;
}
@media (min-width: 1200px) {
  .info-filters-mobile {
    display: none;
  }
}
@media (max-width: 1200px) {
  .info-filters-mobile {
    display: block;
  }
  .info-filters-desktop {
    display: none;
  }
  .catalog-divider {
    display: none;
  }
  .catalog-container {
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .mobile-available {
    margin-top: 20px;
  }
}
.ant-checkbox-wrapper {
  display: flex !important;
  align-items: center !important;
}
.ant-checkbox-wrapper .ant-checkbox {
  margin: 0 !important;
}
.ant-divider-vertical {
  height: 100% !important;
}
.body-container {
  max-width: 1440px;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
}
.main-container {
  padding: 0;
}
.layout-container {
  background: white;
  overflow: hidden;
}
.navbar-padding {
  padding: 0 10px;
}
.container-technologies-img {
  border: 1px solid #dcdcdc;
  width: 100%;
  height: 200px;
}
.technologies .slick-slide {
  margin: 20px !important;
}
.scrollable-container {
  max-height: 250px;
  overflow: auto;
}
.scrollable-container::-webkit-scrollbar {
  width: 11px;
}
.scrollable-container {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.scrollable-container::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.scrollable-container::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
@media (max-width: 1200px) {
  .navbar-padding {
    padding: 0 45px;
  }
  .main-container {
    padding: 0 45px;
  }
}
@media (max-width: 576px) {
  .navbar-padding {
    padding: 0 10px;
  }
  .main-container {
    padding: 0 10px;
  }
}
.pagination-wrapper {
  text-align: right;
  margin-top: 30px;
  margin-bottom: 20px;
}
.categories-row .ant-col-md-12 {
  margin-bottom: 15px;
}
.spin-wrapper {
  height: 400vh;
  z-index: 9999;
}
.ant-pagination-item {
  border-radius: 0 !important;
}
.ant-pagination-prev,
.ant-pagination-next {
  border-radius: 0 !important;
}
.ant-pagination-item-link {
  border-radius: 0 !important;
}
.product-short-container {
  padding: 0 0 16px 0;
  height: 100% !important;
}
.bordered-image {
  border: 1px solid #dcdcdc !important;
  width: 100%;
  min-height: 400px !important;
  height: 400px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-fluid {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}
.center-image {
  display: block;
  margin: 0 auto;
  max-width: calc(100% - 2px);
  max-height: 100%;
}
.center-image-slider {
  display: block;
  max-height: 100%;
}
.center-image-long {
  border: 1px solid #dcdcdc;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 200px;
}
.list {
  display: flex;
  flex-wrap: wrap;
}
.list-item {
  display: flex;
  margin-top: 12px;
  padding: 0.5em;
  width: 100%;
}
.list-item-nw100 {
  display: flex;
  padding: 0.5em;
}
.list-content {
  /* border: 1px solid #dcdcdc !important; */
  background-color: #fff;
  display: flex !important;
  flex-direction: column !important;
  padding: 1em;
  width: 100%;
  flex: 1 0 auto;
  height: 710px;
}
.ant-collapse-borderless {
  background-color: transparent !important;
}
.extra-image {
  max-width: 63px;
}
.product-info {
  margin-top: 80px;
}
.product-technology-img {
  margin-right: 20px;
}
.info-section-title {
  margin-top: 24px;
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393939;
}
.info-section-title-technical {
  margin: 0 0 5px 0;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393939;
  margin-bottom: 30px;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e2;
}
.product-attributes-container {
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393939;
}
.favorites-container {
  margin-top: 100px;
}
@media (max-width: 1200px) {
  .favorites-container {
    margin-top: 10px !important;
  }
}
@media (max-width: 1024px) {
  .removeFromCart-container {
    margin-top: 10px !important;
  }
}
@media (max-width: 576px) {
  .list-content {
    /* border: 1px solid #dcdcdc !important; */
    background-color: #fff;
    display: flex !important;
    flex-direction: column !important;
    padding: 0;
    width: 100%;
    flex: 1 0 auto;
    height: 600px;
  }
}
.debounce-input {
  width: 100%;
  height: 50px;
  border-radius: 0 !important;
  color: white;
  font-size: 12px;
  transition: 0.3s ease-in;
}
.debounce-inputactive {
  border-color: red;
}
.debounce-input-small {
  width: 100%;
  height: 20px;
  border-radius: 0 !important;
  color: white;
  font-size: 12px;
  transition: 0.3s ease-in;
}
.debounce-input-smallactive {
  border-color: red;
}
.debounce-input-small.ant-input-affix-wrapper .ant-input-suffix {
  top: 80%;
}
@media (min-width: 576px) {
  .counter-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: -20px;
    left: -1px;
  }
  .counter-input {
    padding-left: 5px !important;
  }
}
.counter-container {
  display: flex;
  align-items: center;
}
.counter-container input {
  width: 50px;
  height: 50px;
  border: 1px solid #dcdcdc;
  text-align: center;
  margin-left: 5px;
  top: 5px;
  right: 5px;
  margin-right: 5px;
}
.counter-container input:focus {
  outline: none;
  border: 1px solid #679964;
}
.product-counter-container {
  display: flex;
}
.product-counter-container button {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #dcdcdc;
  background: transparent;
}
.product-counter-container button:focus {
  outline: none;
  border: 1px solid #679964;
}
.product-counter-container input {
  width: 50px;
  height: 50px;
  border: 1px solid #dcdcdc;
  text-align: center;
  margin-left: 5px;
  top: 5px;
  right: 5px;
  margin-right: 5px;
}
.product-counter-container input:focus {
  outline: none;
  border: 1px solid #679964;
}
.counter-input {
  padding-left: 15px !important;
}
.counter-container button {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #dcdcdc;
  background: transparent;
}
.counter-container button:focus {
  outline: none;
  border: 1px solid #679964;
}
.react-tel-input {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
}
.react-tel-input :disabled {
  cursor: not-allowed;
}
.react-tel-input .flag {
  width: 25px;
  height: 20px;
  background: url('/static/images/high-res.png');
  background-repeat: no-repeat;
}
.react-tel-input .ad {
  background-position: -48px -24px;
}
.react-tel-input .ae {
  background-position: -72px -24px;
}
.react-tel-input .af {
  background-position: -96px -24px;
}
.react-tel-input .ag {
  background-position: -120px -24px;
}
.react-tel-input .ai {
  background-position: -144px -24px;
}
.react-tel-input .al {
  background-position: -168px -24px;
}
.react-tel-input .am {
  background-position: -192px -24px;
}
.react-tel-input .an {
  background-position: -216px -24px;
}
.react-tel-input .ao {
  background-position: -240px -24px;
}
.react-tel-input .aq {
  background-position: -264px -24px;
}
.react-tel-input .ar {
  background-position: -288px -24px;
}
.react-tel-input .as {
  background-position: -312px -24px;
}
.react-tel-input .at {
  background-position: -336px -24px;
}
.react-tel-input .au {
  background-position: -360px -24px;
}
.react-tel-input .aw {
  background-position: -384px -24px;
}
.react-tel-input .ax {
  background-position: 0px -48px;
}
.react-tel-input .az {
  background-position: -24px -48px;
}
.react-tel-input .ba {
  background-position: -48px -48px;
}
.react-tel-input .bb {
  background-position: -72px -48px;
}
.react-tel-input .bd {
  background-position: -96px -48px;
}
.react-tel-input .be {
  background-position: -120px -48px;
}
.react-tel-input .bf {
  background-position: -144px -48px;
}
.react-tel-input .bg {
  background-position: -168px -48px;
}
.react-tel-input .bh {
  background-position: -192px -48px;
}
.react-tel-input .bi {
  background-position: -216px -48px;
}
.react-tel-input .bj {
  background-position: -240px -48px;
}
.react-tel-input .bl {
  background-position: -264px -48px;
}
.react-tel-input .bm {
  background-position: -288px -48px;
}
.react-tel-input .bn {
  background-position: -312px -48px;
}
.react-tel-input .bo {
  background-position: -336px -48px;
}
.react-tel-input .br {
  background-position: -360px -48px;
}
.react-tel-input .bs {
  background-position: -384px -48px;
}
.react-tel-input .bt {
  background-position: 0px -72px;
}
.react-tel-input .bw {
  background-position: -24px -72px;
}
.react-tel-input .by {
  background-position: -48px -72px;
}
.react-tel-input .bz {
  background-position: -72px -72px;
}
.react-tel-input .ca {
  background-position: -96px -72px;
}
.react-tel-input .cc {
  background-position: -120px -72px;
}
.react-tel-input .cd {
  background-position: -144px -72px;
}
.react-tel-input .cf {
  background-position: -168px -72px;
}
.react-tel-input .cg {
  background-position: -192px -72px;
}
.react-tel-input .ch {
  background-position: -216px -72px;
}
.react-tel-input .ci {
  background-position: -240px -72px;
}
.react-tel-input .ck {
  background-position: -264px -72px;
}
.react-tel-input .cl {
  background-position: -288px -72px;
}
.react-tel-input .cm {
  background-position: -312px -72px;
}
.react-tel-input .cn {
  background-position: -336px -72px;
}
.react-tel-input .co {
  background-position: -360px -72px;
}
.react-tel-input .cr {
  background-position: -384px -72px;
}
.react-tel-input .cu {
  background-position: 0px -96px;
}
.react-tel-input .cv {
  background-position: -24px -96px;
}
.react-tel-input .cw {
  background-position: -48px -96px;
}
.react-tel-input .cx {
  background-position: -72px -96px;
}
.react-tel-input .cy {
  background-position: -96px -96px;
}
.react-tel-input .cz {
  background-position: -120px -96px;
}
.react-tel-input .de {
  background-position: -144px -96px;
}
.react-tel-input .dj {
  background-position: -168px -96px;
}
.react-tel-input .dk {
  background-position: -192px -96px;
}
.react-tel-input .dm {
  background-position: -216px -96px;
}
.react-tel-input .do {
  background-position: -240px -96px;
}
.react-tel-input .dz {
  background-position: -264px -96px;
}
.react-tel-input .ec {
  background-position: -288px -96px;
}
.react-tel-input .ee {
  background-position: -312px -96px;
}
.react-tel-input .eg {
  background-position: -336px -96px;
}
.react-tel-input .eh {
  background-position: -360px -96px;
}
.react-tel-input .er {
  background-position: -384px -96px;
}
.react-tel-input .es {
  background-position: 0px -120px;
}
.react-tel-input .et {
  background-position: -24px -120px;
}
.react-tel-input .eu {
  background-position: -48px -120px;
}
.react-tel-input .fi {
  background-position: -72px -120px;
}
.react-tel-input .fj {
  background-position: -96px -120px;
}
.react-tel-input .fk {
  background-position: -120px -120px;
}
.react-tel-input .fm {
  background-position: -144px -120px;
}
.react-tel-input .fo {
  background-position: -168px -120px;
}
.react-tel-input .fr {
  background-position: -192px -120px;
}
.react-tel-input .ga {
  background-position: -216px -120px;
}
.react-tel-input .gb {
  background-position: -240px -120px;
}
.react-tel-input .gd {
  background-position: -264px -120px;
}
.react-tel-input .ge {
  background-position: -288px -120px;
}
.react-tel-input .gg {
  background-position: -312px -120px;
}
.react-tel-input .gh {
  background-position: -336px -120px;
}
.react-tel-input .gi {
  background-position: -360px -120px;
}
.react-tel-input .gl {
  background-position: -384px -120px;
}
.react-tel-input .gm {
  background-position: 0px -144px;
}
.react-tel-input .gn {
  background-position: -24px -144px;
}
.react-tel-input .gq {
  background-position: -48px -144px;
}
.react-tel-input .gr {
  background-position: -72px -144px;
}
.react-tel-input .gs {
  background-position: -96px -144px;
}
.react-tel-input .gt {
  background-position: -120px -144px;
}
.react-tel-input .gu {
  background-position: -144px -144px;
}
.react-tel-input .gw {
  background-position: -168px -144px;
}
.react-tel-input .gy {
  background-position: -192px -144px;
}
.react-tel-input .hk {
  background-position: -216px -144px;
}
.react-tel-input .hn {
  background-position: -240px -144px;
}
.react-tel-input .hr {
  background-position: -264px -144px;
}
.react-tel-input .ht {
  background-position: -288px -144px;
}
.react-tel-input .hu {
  background-position: -312px -144px;
}
.react-tel-input .ic {
  background-position: -336px -144px;
}
.react-tel-input .id {
  background-position: -360px -144px;
}
.react-tel-input .ie {
  background-position: -384px -144px;
}
.react-tel-input .il {
  background-position: 0px -168px;
}
.react-tel-input .im {
  background-position: -24px -168px;
}
.react-tel-input .in {
  background-position: -48px -168px;
}
.react-tel-input .iq {
  background-position: -72px -168px;
}
.react-tel-input .ir {
  background-position: -96px -168px;
}
.react-tel-input .is {
  background-position: -120px -168px;
}
.react-tel-input .it {
  background-position: -144px -168px;
}
.react-tel-input .je {
  background-position: -168px -168px;
}
.react-tel-input .jm {
  background-position: -192px -168px;
}
.react-tel-input .jo {
  background-position: -216px -168px;
}
.react-tel-input .jp {
  background-position: -240px -168px;
}
.react-tel-input .ke {
  background-position: -264px -168px;
}
.react-tel-input .kg {
  background-position: -288px -168px;
}
.react-tel-input .kh {
  background-position: -312px -168px;
}
.react-tel-input .ki {
  background-position: -336px -168px;
}
.react-tel-input .km {
  background-position: -360px -168px;
}
.react-tel-input .kn {
  background-position: -384px -168px;
}
.react-tel-input .kp {
  background-position: 0px -192px;
}
.react-tel-input .kr {
  background-position: -24px -192px;
}
.react-tel-input .kw {
  background-position: -48px -192px;
}
.react-tel-input .ky {
  background-position: -72px -192px;
}
.react-tel-input .kz {
  background-position: -96px -192px;
}
.react-tel-input .la {
  background-position: -120px -192px;
}
.react-tel-input .lb {
  background-position: -144px -192px;
}
.react-tel-input .lc {
  background-position: -168px -192px;
}
.react-tel-input .li {
  background-position: -192px -192px;
}
.react-tel-input .lk {
  background-position: -216px -192px;
}
.react-tel-input .lr {
  background-position: -240px -192px;
}
.react-tel-input .ls {
  background-position: -264px -192px;
}
.react-tel-input .lt {
  background-position: -288px -192px;
}
.react-tel-input .lu {
  background-position: -312px -192px;
}
.react-tel-input .lv {
  background-position: -336px -192px;
}
.react-tel-input .ly {
  background-position: -360px -192px;
}
.react-tel-input .ma {
  background-position: -384px -192px;
}
.react-tel-input .mc {
  background-position: 0px -216px;
}
.react-tel-input .md {
  background-position: -24px -216px;
}
.react-tel-input .me {
  background-position: -48px -216px;
}
.react-tel-input .mf {
  background-position: -72px -216px;
}
.react-tel-input .mg {
  background-position: -96px -216px;
}
.react-tel-input .mh {
  background-position: -120px -216px;
}
.react-tel-input .mk {
  background-position: -144px -216px;
}
.react-tel-input .ml {
  background-position: -168px -216px;
}
.react-tel-input .mm {
  background-position: -192px -216px;
}
.react-tel-input .mn {
  background-position: -216px -216px;
}
.react-tel-input .mo {
  background-position: -240px -216px;
}
.react-tel-input .mp {
  background-position: -264px -216px;
}
.react-tel-input .mq {
  background-position: -288px -216px;
}
.react-tel-input .mr {
  background-position: -312px -216px;
}
.react-tel-input .ms {
  background-position: -336px -216px;
}
.react-tel-input .mt {
  background-position: -360px -216px;
}
.react-tel-input .mu {
  background-position: -384px -216px;
}
.react-tel-input .mv {
  background-position: 0px -240px;
}
.react-tel-input .mw {
  background-position: -24px -240px;
}
.react-tel-input .mx {
  background-position: -48px -240px;
}
.react-tel-input .my {
  background-position: -72px -240px;
}
.react-tel-input .mz {
  background-position: -96px -240px;
}
.react-tel-input .na {
  background-position: -120px -240px;
}
.react-tel-input .nc {
  background-position: -144px -240px;
}
.react-tel-input .ne {
  background-position: -168px -240px;
}
.react-tel-input .nf {
  background-position: -192px -240px;
}
.react-tel-input .ng {
  background-position: -216px -240px;
}
.react-tel-input .ni {
  background-position: -240px -240px;
}
.react-tel-input .nl {
  background-position: -264px -240px;
}
.react-tel-input .no {
  background-position: -288px -240px;
}
.react-tel-input .np {
  background-position: -312px -240px;
}
.react-tel-input .nr {
  background-position: -336px -240px;
}
.react-tel-input .nu {
  background-position: -360px -240px;
}
.react-tel-input .nz {
  background-position: -384px -240px;
}
.react-tel-input .om {
  background-position: 0px -264px;
}
.react-tel-input .pa {
  background-position: -24px -264px;
}
.react-tel-input .pe {
  background-position: -48px -264px;
}
.react-tel-input .pf {
  background-position: -72px -264px;
}
.react-tel-input .pg {
  background-position: -96px -264px;
}
.react-tel-input .ph {
  background-position: -120px -264px;
}
.react-tel-input .pk {
  background-position: -192px -264px;
}
.react-tel-input .pl {
  background-position: -216px -264px;
}
.react-tel-input .pn {
  background-position: -240px -264px;
}
.react-tel-input .pr {
  background-position: -264px -264px;
}
.react-tel-input .ps {
  background-position: -288px -264px;
}
.react-tel-input .pt {
  background-position: -312px -264px;
}
.react-tel-input .pw {
  background-position: -336px -264px;
}
.react-tel-input .py {
  background-position: -360px -264px;
}
.react-tel-input .qa {
  background-position: -384px -264px;
}
.react-tel-input .ro {
  background-position: 0px -288px;
}
.react-tel-input .rs {
  background-position: -24px -288px;
}
.react-tel-input .ru {
  background-position: -48px -288px;
}
.react-tel-input .rw {
  background-position: -72px -288px;
}
.react-tel-input .sa {
  background-position: -96px -288px;
}
.react-tel-input .sb {
  background-position: -120px -288px;
}
.react-tel-input .sc {
  background-position: -144px -288px;
}
.react-tel-input .sd {
  background-position: -168px -288px;
}
.react-tel-input .se {
  background-position: -192px -288px;
}
.react-tel-input .sg {
  background-position: -216px -288px;
}
.react-tel-input .sh {
  background-position: -240px -288px;
}
.react-tel-input .si {
  background-position: -264px -288px;
}
.react-tel-input .sk {
  background-position: -288px -288px;
}
.react-tel-input .sl {
  background-position: -312px -288px;
}
.react-tel-input .sm {
  background-position: -336px -288px;
}
.react-tel-input .sn {
  background-position: -360px -288px;
}
.react-tel-input .so {
  background-position: -384px -288px;
}
.react-tel-input .sr {
  background-position: 0px -312px;
}
.react-tel-input .ss {
  background-position: -24px -312px;
}
.react-tel-input .st {
  background-position: -48px -312px;
}
.react-tel-input .sv {
  background-position: -72px -312px;
}
.react-tel-input .sy {
  background-position: -96px -312px;
}
.react-tel-input .sz {
  background-position: -120px -312px;
}
.react-tel-input .tc {
  background-position: -144px -312px;
}
.react-tel-input .td {
  background-position: -168px -312px;
}
.react-tel-input .tf {
  background-position: -192px -312px;
}
.react-tel-input .tg {
  background-position: -216px -312px;
}
.react-tel-input .th {
  background-position: -240px -312px;
}
.react-tel-input .tj {
  background-position: -264px -312px;
}
.react-tel-input .tk {
  background-position: -288px -312px;
}
.react-tel-input .tl {
  background-position: -312px -312px;
}
.react-tel-input .tm {
  background-position: -336px -312px;
}
.react-tel-input .tn {
  background-position: -360px -312px;
}
.react-tel-input .to {
  background-position: -384px -312px;
}
.react-tel-input .tr {
  background-position: 0px -336px;
}
.react-tel-input .tt {
  background-position: -24px -336px;
}
.react-tel-input .tv {
  background-position: -48px -336px;
}
.react-tel-input .tw {
  background-position: -72px -336px;
}
.react-tel-input .tz {
  background-position: -96px -336px;
}
.react-tel-input .ua {
  background-position: -120px -336px;
}
.react-tel-input .ug {
  background-position: -144px -336px;
}
.react-tel-input .us {
  background-position: -168px -336px;
}
.react-tel-input .uy {
  background-position: -192px -336px;
}
.react-tel-input .uz {
  background-position: -216px -336px;
}
.react-tel-input .va {
  background-position: -240px -336px;
}
.react-tel-input .vc {
  background-position: -264px -336px;
}
.react-tel-input .ve {
  background-position: -288px -336px;
}
.react-tel-input .vg {
  background-position: -312px -336px;
}
.react-tel-input .vi {
  background-position: -336px -336px;
}
.react-tel-input .vn {
  background-position: -360px -336px;
}
.react-tel-input .vu {
  background-position: -384px -336px;
}
.react-tel-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.react-tel-input .hide {
  display: none;
}
.react-tel-input .v-hide {
  visibility: hidden;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 15px;
  letter-spacing: 0.01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 60px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 0;
  line-height: 25px;
  height: 50px;
  width: 100%;
  outline: none;
}
.react-tel-input .form-control.invalid-number {
  border: 1px solid #d79f9f;
  background-color: #faf0f0;
  border-left-color: #cacaca;
}
.react-tel-input .form-control.invalid-number:focus {
  border: 1px solid #d79f9f;
  border-left-color: #cacaca;
  background-color: #faf0f0;
}
.react-tel-input .form-control.open {
  z-index: 2;
}
.react-tel-input .flag-dropdown {
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .flag-dropdown.open {
  z-index: 2;
  background: #fff;
  border-radius: 3px 0 0 0;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #fff;
  border-radius: 3px 0 0 0;
}
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  cursor: pointer;
}
.react-tel-input .flag-dropdown:hover .selected-flag,
.react-tel-input .flag-dropdown:focus .selected-flag {
  background-color: #fff;
}
.react-tel-input input[disabled] + .flag-dropdown:hover {
  cursor: default;
}
.react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
  background-color: transparent;
}
.react-tel-input .selected-flag {
  position: relative;
  width: 49px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  margin-top: -12px;
}
.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -1px;
  left: 30px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.react-tel-input .country-list {
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: white;
  width: 300px;
  max-height: 224px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}
.react-tel-input .country-list .flag {
  position: absolute;
  left: 10px;
  top: 15px;
}
.react-tel-input .country-list .divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
.react-tel-input .country-list .country {
  padding: 7px 9px 7px 44px;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
}
.react-tel-input .country-list .country .dial-code {
  color: #6b6b6b;
}
.react-tel-input .country-list .country:hover {
  background-color: #f1f1f1;
}
.react-tel-input .country-list .country.highlight {
  background-color: #f1f1f1;
}
.react-tel-input .country-list .country-name {
  margin-right: 6px;
}
.react-tel-input .country-list .search {
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 10px 0 6px 10px;
}
.react-tel-input .country-list .search-emoji {
  font-size: 15px;
}
.react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 3px 8px 5px;
  outline: none;
}
.react-tel-input .country-list .no-entries-message {
  padding: 7px 10px 11px;
  opacity: 0.7;
}
.text-center {
  text-align: center;
}
.ant-select-selection {
  border-radius: 0 !important;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}
.title {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
}
.title-container {
  margin-bottom: 20px;
}
.subtitle {
  font-size: 20px;
  font-weight: normal;
  color: #fff;
}
.green-container {
  background-color: rgba(83, 109, 82, 0.76);
  padding: 100px;
}
.black-container {
  background-color: #444343;
  padding: 140px 50px;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.grey-container {
  background-color: #d8d8d8;
  padding: 140px 50px;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.white-container {
  background-color: #fff;
  padding: 140px 50px;
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 768px) {
  .white-container,
  .grey-container,
  .black-container {
    padding: 50px 50px !important;
  }
  .title {
    font-size: 25px;
    font-weight: bold;
    color: #fff;
  }
  .title-container {
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: 15px;
    font-weight: normal;
    color: #fff;
  }
  .white-container,
  .grey-container,
  .black-container {
    height: 100%;
  }
}
@media (max-width: 576px) {
  .green-container {
    background-color: rgba(83, 109, 82, 0.76);
    padding: 50px;
  }
  .white-container,
  .grey-container,
  .black-container {
    height: 100%;
  }
  .green-list {
    list-style: none !important;
    margin: 10px 0;
  }
}
.product-col-buy-list {
  padding-left: 100px !important;
}
.product-col-rate-list {
  padding-right: 100px !important;
}
@media (min-width: 576px) {
  .product-slider-grid {
    padding-right: 20px !important;
  }
}
.settlements-collapse {
  margin-right: 30px;
}
.product-col-buy-grid {
  padding-left: 80px !important;
}
@media (min-width: 1200px) {
  .product-col-buy-grid {
    padding-left: 80px !important;
    margin-left: 20px;
  }
  .product-slider .slick-prev {
    left: 10px !important;
  }
  .product-slider .slick-next {
    right: 10px !important;
  }
}
.product-col-rate-grid {
  padding: 0 !important;
}
.product-desciption {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 1200px) {
  .product-slider .slick-list {
    margin: 0 auto !important;
    width: 90% !important;
  }
}
.slider-main-image-container {
  border: 1px solid #dcdcdc;
}
.gallery-img-container {
  border: 1px solid #dcdcdc !important;
  padding: 5px !important;
  height: 140px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.gallery-img-container img {
  max-width: 100% !important;
}
@media (max-width: 1200px) {
  .product-col-buy-grid {
    padding: 0 !important;
  }
  .product-col-buy-list {
    padding-left: 0 !important;
  }
  .product-desciption {
    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }
}
@media (min-width: 900px) and (max-width: 1500px) {
  .product-desktop-container {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (max-width: 768px) {
  .product-simple-btn {
    margin-bottom: 0px;
  }
}
@media (max-width: 576px) {
  .productSimple-btns {
    text-align: start !important;
  }
}
.newarrival-img-container {
  border: 1px solid #dcdcdc;
  padding: 30px;
  height: 250px;
  width: 300px;
}
.product-img-long {
  max-height: 100%;
}
@media (min-width: 320px) and (max-width: 576px) {
  .phone {
    display: block;
    max-width: 100%;
  }
  .tablet {
    display: none;
    max-width: 100%;
  }
  .desktop {
    display: none;
    max-width: 100%;
  }
  .product-slider-desktop {
    display: none;
  }
  .product-slider-mobile {
    display: block;
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .phone {
    display: none;
    max-width: 100%;
  }
  .tablet {
    display: block;
    max-width: 100%;
  }
  .desktop {
    display: none;
    max-width: 100%;
  }
  .product-slider-desktop {
    display: none;
  }
  .product-slider-mobile {
    display: none;
  }
}
@media (min-width: 1200px) {
  .phone {
    display: none;
    max-width: 100%;
  }
  .tablet {
    display: none;
    max-width: 100%;
  }
  .desktop {
    display: block;
    max-width: 100%;
  }
  .product-slider-mobile {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .product-layout-section .product-cart-section {
    height: 370px;
    order: 1 !important;
  }
  .gallery-layout {
    width: 110px;
    height: 110px;
  }
  .gallery-layout img {
    width: 70px;
  }
}
.home-section .anticon-right,
.home-section .anticon-left svg {
  font-size: 50px !important;
}
.home-main-container {
  padding: 0 75px;
}
.budgetProducts-container {
  display: flex;
  justify-content: center;
}
.budgetProducts-container .ant-tabs-tab-active {
  border: 1px solid #dcdcdc !important;
}
.react-tabs__tab-list {
  list-style: none;
  display: flex;
}
.home-section .slick-slider {
  width: 100% !important;
}
.home-section .ant-tabs-nav-scroll {
  text-align: center;
}
.home-section .ant-tabs-nav .ant-tabs-tab {
  padding: 20px !important;
  border: 1px solid #dcdcdc;
  width: 400px;
  overflow: hidden;
  margin: 0 10px 0 0 !important ;
}
@media (max-width: 1200px) {
  .home-video-container {
    height: 600px;
  }
}
@media (max-width: 576px) {
  .home-section .ant-tabs-nav .ant-tabs-tab {
    padding: 15px !important;
    border: 1px solid #dcdcdc;
    width: 260px;
    overflow: hidden;
    font-size: 12px !important;
  }
  .home-video-container {
    height: 300px;
  }
}
@media (max-width: 320px) {
  .home-section .ant-tabs-nav .ant-tabs-tab {
    padding: 15px !important;
    border: 1px solid #dcdcdc;
    width: 220px;
    overflow: hidden;
  }
}
@media (max-width: 768px) {
  .home-main-container {
    padding: 0 5px;
  }
  .earplugs-video {
    width: auto !important;
    height: 400px !important;
  }
}
@media (max-width: 576px) {
  height: 320px !important;
}
.grid-component {
  padding: 100px 20px;
  text-align: center;
}
.grid-component-img-container {
  padding: 10px;
  width: 100%;
  height: auto;
}
.grid-component-title {
  z-index: 2 !important;
  font-size: 24px;
}
.grid-component-subtitle {
  z-index: 2 !important;
  font-size: 21px;
}
.grid-component-img {
  position: absolute;
  padding: 10px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.heroSlider .slick-slider {
  width: 100% !important;
}
.gallery-layout {
  align-items: center;
  display: flex;
  width: 100%;
  height: 200px;
  border: solid 1px #cccccc;
  justify-content: center;
}
.gallery-layout:hover {
  cursor: pointer;
  border-color: #2c2c37;
}
.slick-initialized .slick-slide {
  padding: 5px !important;
}
.gallery-layout-list {
  align-items: center;
  display: flex;
  width: 125px;
  height: 125px;
  border: solid 1px #cccccc;
  margin-top: 20px;
  justify-content: center;
}
.gallery-layout-list:hover {
  cursor: pointer;
  border-color: #2c2c37;
}
.gallery-layout-mobile {
  display: flex;
  width: 80px;
  height: 100px;
  justify-content: center;
}
.gallery-layout-mobile:hover {
  cursor: pointer;
  border-color: #2c2c37;
}
.gallery-layout {
  margin-top: 15px;
  margin-bottom: 15px;
}
.gallery-layout img {
  width: 100%;
  transition: all 0.2s ease-in-out;
  margin: 0 10px 0 10px;
}
.product-gallery-section .active-product-img {
  width: 900px !important;
}
.product-gallery-section.vertical .active-product-img {
  width: 750px !important;
}
.product-gallery-section.vertical .gallery-layout {
  margin-right: 0px;
  margin-bottom: 20px;
}
.thumbs-slider {
  width: 50%;
  margin: 0 auto;
}
.product-gallery-section.vertical .thumbs-slider {
  width: 100%;
  height: 500px;
}
.product-layout-section .share-title {
  font-family: PHAgoraSansBold;
  font-size: 15px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393839;
}
.video-btn {
  align-items: center;
  border: 1px solid #e2e2e2;
  display: flex !important;
  width: 100% !important;
  height: 90%;
  justify-content: center;
  font-size: 60px;
}
.video-btn svg {
  transition: all 0.2s ease-in-out;
}
.video-btn:hover svg {
  transform: scale(1.1);
}
.slider-product-img {
  max-height: 90% !important;
}
.product-img-container {
  width: 100%;
  height: auto;
  display: flex !important;
  justify-content: middle !important;
  align-items: middle !important;
}
@media (max-width: 576px) {
  .product-img-container {
    width: 100%;
    height: 300px;
    max-width: 100%;
    display: flex;
    justify-content: middle;
    align-items: middle;
  }
}
.product-slider-desktop {
  border: 1px solid #dcdcdc;
  max-width: 1260px;
}
.product-img-container-mobile {
  border: 1px solid #dcdcdc;
}
.product-img-main {
  max-height: 300px;
}
.slider-vertical div {
  margin-left: 10px;
  margin-right: 10px;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  position: absolute;
  z-index: 2;
  top: 20px;
  background: 0 0;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}
.carousel .control-arrow:hover {
  opacity: 1;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
}
.carousel .control-disabled.control-arrow {
  opacity: 0;
  cursor: inherit;
  display: none;
}
.carousel .control-prev.control-arrow {
  left: 0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}
.carousel .control-next.control-arrow {
  right: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}
.carousel {
  position: relative;
  width: 100%;
}
.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}
.carousel .carousel {
  position: relative;
}
.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: 0 0;
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}
.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}
.carousel .thumbs {
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  -webkit-transition: border 0.15s ease-in;
  -moz-transition: border 0.15s ease-in;
  -ms-transition: border 0.15s ease-in;
  -o-transition: border 0.15s ease-in;
  transition: border 0.15s ease-in;
  display: inline-block;
  width: 80px;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
.carousel .thumb:focus {
  border: 1px solid #ccc;
  outline: 0;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 1px solid #333;
}
.carousel .thumb img {
  vertical-align: top;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -ms-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}
.carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #000;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  text-align: center;
  width: 100%;
}
@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}
.carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
.carousel:hover .slide .legend {
  opacity: 1;
}
/* HeroSlider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-initialized .slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  margin-right: auto;
}
.slick-initialized .slick-track:before,
.slick-initialized .slick-track:after {
  content: '';
  display: table;
}
.slick-initialized .slick-track:after {
  clear: both;
}
.slick-loading .slick-initialized .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
  width: 1fr;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* HeroSlider */
.slick-loading .slick-list {
  background: #fff url('/static/images/ajax-loader.gif') center center no-repeat;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  line-height: 0px;
  font-size: 30px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 40%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Icons */
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('/static/fonts/Slick/slick.eot');
  src: url('/static/fonts/Slick/slick.eot?#iefix') format('embedded-opentype'), url('/static/fonts/Slick/slick.woff') format('woff'), url('/static/fonts/Slick/slick.ttf') format('truetype'), url('/static/fonts/Slick/slick.svg#slick') format('svg');
}
.slick-prev {
  left: -35px;
}
[dir='rtl'] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: '\2190';
}
[dir='rtl'] .slick-prev:before {
  content: '\2192';
}
.slick-next {
  right: -35px;
}
[dir='rtl'] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: '\2192';
}
[dir='rtl'] .slick-next:before {
  content: '\2190';
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.video-dots .slick-dots {
  bottom: -25px;
}
.slick-dots {
  position: absolute;
  bottom: -50px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\2022';
  width: 20px;
  height: 20px;
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
@media (max-width: 576px) {
  .slick-dots {
    display: none !important;
  }
}
.p-megamenu {
  padding: 0.25em;
}
.p-megamenu-root-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.p-megamenu-root-list > .p-menuitem {
  position: relative;
}
.p-megamenu .p-menuitem-link {
  padding: 0.25em;
  display: block;
  text-decoration: none;
}
.p-megamenu .p-menuitem-icon {
  margin-right: 0.25em;
  vertical-align: middle;
}
.p-megamenu .p-menuitem-text {
  vertical-align: middle;
}
.p-megamenu-panel {
  display: none;
  position: absolute;
  width: auto;
  z-index: 1;
}
.p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
  display: block;
}
.p-megamenu-panel .p-menuitem {
  margin: 0.125em 0;
}
.p-megamenu-submenu {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 12.5em;
}
.p-megamenu-submenu-header {
  padding: 0.25em;
}
/* Horizontal */
.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem {
  display: inline-block;
}
.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
  vertical-align: middle;
  margin-left: 0.25em;
}
/* Vertical */
.p-megamenu-vertical {
  width: 12.5em;
}
.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem {
  display: block;
}
.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  position: relative;
}
.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem-active > .p-megamenu-panel {
  left: 100%;
  top: 0;
}
.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  right: 0;
  margin-top: -0.5em;
}
.p-megamenu .p-grid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.stores-container .green-box {
  height: 600px;
  background-color: #536d52;
  padding: 190px 220px;
}
.stores-container .green-box .title {
  font-size: 30px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 39px;
}
.stores-container .green-box .content {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  list-style: none;
  padding-left: 0;
}
.stores-container .black-box {
  height: 600px;
  background-color: #444343;
  padding: 190px 220px;
}
.stores-container .black-box .title {
  font-size: 30px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 39px;
}
.stores-container .black-box .content {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  list-style: none;
  padding-left: 0;
}
.stores-container .grey-box {
  height: 600px;
  background-color: #d8d8d8;
  padding: 100px 100px;
}
.stores-container .grey-box .content {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #393839;
  margin-bottom: 13px;
}
.stores-container .grey-box .mini-title {
  font-size: 17px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #393839;
  margin-bottom: 11px;
  margin-top: 23px;
}
.stores-container .grey-box .content.list {
  padding-left: 0px;
  list-style: none;
}
.map-wrapper {
  height: 750px;
}
@media (max-width: 1024px) {
  .stores-container .green-box {
    height: 600px;
    background-color: #536d52;
    padding: 140px 50px;
  }
  .stores-container .grey-box {
    padding: 50px;
  }
  .stores-container .black-box {
    padding: 140px 50px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .mobile-order-1 {
    order: 1 !important;
  }
  .mobile-order-2 {
    order: 2 !important;
  }
  .mobile-order-3 {
    order: 3 !important;
  }
  .mobile-order-4 {
    order: 4 !important;
  }
  .mobile-order-5 {
    order: 5 !important;
  }
  .stores-container .ant-row {
    display: flex;
    flex-direction: column;
  }
  .stores-container .green-box {
    height: 400px;
    padding: 100px 50px;
  }
  .stores-container .black-box {
    height: 400px;
    padding: 100px 50px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .mobile-order-1 {
    order: 1 !important;
  }
  .mobile-order-2 {
    order: 2 !important;
  }
  .mobile-order-3 {
    order: 3 !important;
  }
  .mobile-order-4 {
    order: 4 !important;
  }
  .mobile-order-5 {
    order: 5 !important;
  }
  .stores-container .ant-row {
    display: flex;
    flex-direction: column;
  }
  .stores-container .green-box {
    height: 400px;
    padding: 100px 50px;
  }
  .stores-container .black-box {
    height: 400px;
    padding: 100px 50px;
  }
}
.input-range__slider {
  appearance: none;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px transparentize(#eeeeee, 0.8);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}
.input-range__slider-container {
  /*  transition: @input-range-slider-container-transition;*/
  transition: none;
}
.rtl .input-range__slider {
  margin-left: auto;
  margin-right: -0.5rem;
}
.rtl .input-range__slider-container {
  transition: right 0.3s ease-out;
}
.input-range__slider {
  margin-left: -0.5rem;
}
.input-range__label {
  color: #aaaaaa;
  font-family: 'Helvetica Neue', san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}
.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}
.input-range__label--min {
  left: 0;
}
.input-range__label--max {
  right: 0;
}
.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}
.rtl .input-range__label--min {
  left: auto;
  right: 0;
}
.rtl .input-range__label--max {
  left: 0;
  right: auto;
}
.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}
.rtl .input-range__label-container {
  left: auto;
  right: -50%;
  position: relative;
}
.input-range__label--max .rtl .input-range__label-container {
  left: auto;
  right: 50%;
}
.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}
.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}
.input-range__track--active {
  background: #eeeeee;
}
.rtl .input-range__track {
  transition: right 0.3s ease-out, width 0.3s ease-out;
}
.input-range__track {
  /* transition: @input-range-track-transition;*/
  transition: none;
}
.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}
.rtl.input-range {
  direction: rtl;
}
@font-face {
  src: url('/ufonts.com_pfagorasanspro-regular.ttf');
  font-family: Agora;
}
@font-face {
  src: url('/ufonts.com_pfagorasanspro-medium.ttf');
  font-family: AgoraBold;
}
p,
span {
  font-family: Agora !important;
}
ul {
  margin: 0 !important;
}
.light-text {
  color: #fff !important;
}
.dark-text {
  color: #090909;
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bold;
  font-family: AgoraBold;
}
.subtitle-text {
  font-size: 18px;
  color: #f89d04;
}
.subtitle-text-small {
  color: #f89d04;
  font-size: 14px;
}
.text-48-normal {
  font-size: 48px;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
}
.text-48-bold {
  font-size: 48px;
  font-weight: bold;
}
.text-48-mute {
  font-size: 48px;
  font-weight: bold;
  color: #686868;
}
.text-48-mute-bold {
  font-size: 48px;
  font-weight: bold;
  color: #686868;
}
.text-36-normal {
  font-size: 36px;
  color: #686868;
}
.text-35-normal {
  font-size: 35px;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
  color: #444658;
}
.text-35-bold {
  font-size: 35px;
  font-weight: bold;
}
.text-35-mute {
  font-size: 35px;
  font-weight: bold;
  color: #686868;
}
.text-35-mute-bold {
  font-size: 35px;
  font-weight: bold;
  color: #686868;
}
.text-24-normal {
  font-size: 24px;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
}
.text-24-bold {
  font-size: 24px;
  font-weight: bold;
}
.text-24-bold-price {
  font-size: 24px;
  font-weight: bold;
  color: #444658;
}
.text-24-mute {
  font-size: 24px;
  font-weight: bold;
  color: #686868;
}
.text-24-mute-bold {
  font-size: 24px;
  font-weight: bold;
  color: #686868;
}
.text-21-bold {
  font-size: 21px;
  color: #090909;
  font-weight: bold;
}
.text-20-normal {
  font-size: 20px;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
}
.text-20-bold {
  font-size: 20px;
  font-weight: bold;
}
.text-20-bold-price {
  font-size: 20px;
  font-weight: bold;
  color: #444658;
}
.text-20-mute {
  font-size: 20px;
  font-weight: bold;
  color: #090909;
}
.text-20-mute-bold {
  font-size: 20px;
  font-weight: bold;
  color: #686868;
}
.text-18-normal {
  font-size: 18px;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
}
.text-18-bold {
  font-size: 18px;
  font-weight: bold;
}
.text-18-bold-green {
  font-size: 18px;
  color: green;
  font-weight: bold;
}
.text-18-bold-link {
  font-size: 18px;
  font-weight: bold;
  color: #646464;
}
.text-18-bold-link:hover {
  cursor: pointer;
  color: #646464;
}
.text-18-mute {
  font-size: 18px;
  font-weight: bold;
  color: #686868;
}
.text-18-mute-bold {
  font-size: 18px;
  font-weight: bold;
  color: #686868;
}
.text-16-normal {
  font-size: 16px;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
  color: #646464;
}
.text-16-normal p,
strong {
  font-size: 16px;
  font-weight: normal !important;
  margin: 0 !important;
  padding: 0 !important;
  color: #646464;
}
.text-14-normal {
  font-size: 14px;
  font-weight: normal;
  margin: 0 !important;
  padding: 0 !important;
  color: #646464;
}
.text-16-bold {
  font-size: 16px;
  font-weight: bold;
  color: #686868;
}
.text-14-red {
  color: red;
  font-size: 14px;
}
.text-16-bold-black {
  font-size: 16px;
  font-weight: bold;
  color: #393939;
}
.text-16-bold-green {
  color: green;
  font-size: 16px;
  font-weight: bold;
}
.text-16-mute {
  font-size: 16px;
  font-weight: normal;
  color: #a9a9a9;
}
.text-14-mute {
  font-size: 14px;
  font-weight: normal;
  color: #a9a9a9;
}
.text-16-mute-bold {
  font-size: 16px;
  font-weight: bold;
  color: #a9a9a9;
}
.text-15-normal {
  font-size: 15px;
  color: #444658;
  font-weight: normal;
}
.text-14-normal-price-from {
  font-size: 16px;
  color: #a9a9a9;
}
.text-12-normal {
  font-size: 12px;
  font-weight: normal;
}
.text-12-bold {
  font-size: 12px;
  font-weight: bold;
}
.text-12-mute {
  font-size: 12px;
  font-weight: normal;
  color: #a9a9a9;
}
.text-12-mute-bold {
  font-size: 12px;
  font-weight: bold;
  color: #a9a9a9;
}
.line-through {
  text-decoration: line-through red;
  display: flex;
  align-items: center;
}
.withpadding {
  padding: 0 0.15em;
}
.ul-no-listing {
  padding: 0 !important;
  list-style: none !important;
}
.ul-no-listing-extras {
  padding: 15px !important;
  list-style: none !important;
}
.li-extras {
  margin-bottom: 30px;
}
.selected .link {
  font-weight: 900;
}
.clear-filters {
  color: #1890ff;
}
.select-colors {
  display: flex;
  flex-direction: row;
}
.colors {
  height: 20px;
  margin-top: 5px;
  width: 20px;
  margin-left: 2px;
  margin-right: 2px;
}
.small-link {
  color: #679964;
}
.text-red {
  color: red;
}
.text-dots {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-below {
  white-space: normal;
  overflow: hidden;
}
.inner-category-title {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #70a46d;
  margin-bottom: 10px;
  margin-top: 0;
}
.inner-category-list li {
  padding-top: 5px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444343;
}
.select-label {
  padding: 5px;
}
.extra-description {
  margin-left: 10px;
  margin-right: 10px;
  color: #393839 !important;
  font-size: 15px;
}
.text-light {
  color: #a9a9a9;
}
.text-white {
  color: white;
}
.text-dark-small {
  font-size: 15px;
  font-weight: normal;
  color: #444343;
}
.settlements-helper {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 20px;
}
.product-title {
  min-height: 75px !important;
  display: block !important;
}
.limited-quantity {
  font-size: 15px;
  font-weight: normal;
  color: #f89d04 !important;
}
@media (max-width: 320px) {
  .text-30-5s {
    font-size: 30px !important;
  }
}
.card {
  margin: 0% 33% 0%;
}
.card-header {
  margin: 0% 35% 0%;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .card {
    margin: auto;
  }
}
@media only screen and (max-width: 600px) {
  .card-header {
    margin: auto;
    width: 60%;
    text-align: center;
  }
}
