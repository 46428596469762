/*.navbar-mobile {
  display: none;
}
.navbar-tablet {
  display: none;
}*/
@media (max-width: 576px) {
  .navbar-mobile {
    display: block;
  }
  .logo {
    width: 170px;
  }
}
@media (min-width: 576px) and (max-width: 1220px) {
  .navbar-tablet {
    display: block;
  }
  .logo {
    width: 190px;
  }
}
@media (min-width: 1220px) {
  .navbar-desktop {
    display: block;
  }
  .logo {
    width: 200px;
  }
}
.search-dropdown-menu {
  border: 1px solid #dcdcdc;
}
.search-dropdown-menu-desktop {
  position: absolute !important;
  background: #ffffff !important;
  z-index: 2 !important;
  border: 1px solid #dcdcdc;
  min-width: 1000px;
}
@media (max-width: 1024px) {
  .search-dropdown-menu-desktop {
    position: absolute !important;
    background: #ffffff !important;
    z-index: 2 !important;
    border: 1px solid #dcdcdc;
    min-width: 100%;
  }
}
.ant-checkbox {
  margin-bottom: 30px !important;
}
.menu-title {
  white-space: nowrap !important;
}
.desktop-navbar {
  border-bottom: 1px solid #dcdcdc;
}
.listing-bar li {
  padding-right: 0 !important;
  padding-bottom: 10px !important;
  margin-right: 30px;
}
.menu-bar {
  margin-top: 26px !important;
  padding-bottom: 10px !important;
}
.listing-bar p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #444343;
}
.listing-bar li {
  padding: 0 30px 0 0;
}
.listing-bar ul {
  border-bottom: none !important;
}
.search-results__articles-item .prices {
  font-size: 17px;
  color: #505050;
}
.search-results__articles-item .prices .from-price {
  position: relative;
  margin-right: 0.5rem;
}
.search-results__articles-item .prices .current-price {
  font-weight: bold;
}
.search-results__articles ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.search-results__articles ul li {
  margin-bottom: 1.125rem;
}
.search-results__articles-item h3 {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
  color: #898989;
}
.search-results__articles-item h3,
.search-results__articles-item .description {
  transition: color 0.3s;
}
.search-results__articles-item .prices .from-price:after {
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(100%);
  height: 0.0625rem;
  margin: 0;
  transform: translateY(-50%);
  background-color: red;
  content: '';
}
