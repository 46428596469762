@media (max-width: 1024px) {
  .cart-mobile {
    display: block;
  }
}
@media (min-width: 1025px) {
  .cart-desktop {
    display: block;
  }
}
