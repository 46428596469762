.product-long-container {
  width: 100%;
}
.main-image-container {
  max-width: 1230px;
}
.product-slides {
  margin: 5px;
}
.img-container-long {
  border: 1px solid #dcdcdc;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
