@media (max-width: 576px) {
  .footer-mobile-container {
    display: block;
  }
  .footer-container {
    padding-left: 10px !important;
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .footer-tablet-container {
    display: block;
  }
  .footer-container {
    padding-left: 45px !important;
    padding-right: 45px;
  }
}
@media (min-width: 1024px) {
  .footer-desktop-container {
    display: block;
  }
}
