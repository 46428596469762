@media (max-width: 1024px) {
  .account-mobile-container {
    display: block;
  }
}
@media (min-width: 1025px) {
  .account-desktop-container {
    display: block;
  }
}
.account-desktop-container {
  padding-left: 40px;
  padding-right: 40px;
}
.account-desktop-forms-container {
  padding-left: 40px;
  border-left: 1px solid #dcdcdc;
}
@media (max-width: 1200px) {
  .price-container {
    display: flex;
    justify-content: center !important;
  }
}
