.product-long-container {
  width: 100%;
}
.main-image-container {
  max-width: 1230px;
}
.product-slides {
  margin: 5px;
}
.img-container-long {
  border: 1px solid #dcdcdc;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-dots {
  overflow: hidden !important;
  max-width: 280px !important;
  text-overflow: unset !important;
  white-space: nowrap !important;
}
.img-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.favorite-btn {
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 10px;
}
.circle-item {
  width: 48px;
  height: 48px;
  background: orange;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
@media (min-width: 576px) {
  .text-dots {
    overflow: hidden !important;
    max-width: 400px !important;
    text-overflow: unset !important;
    white-space: nowrap !important;
  }
}
.product-long-md {
  display: block;
}
.product-long-sm {
  display: block;
}
.address-card-container {
  height: 330px;
  border: 1px solid #dcdcdc;
  margin-bottom: 5px;
}
.card-top-container {
  height: 80%;
  padding: 20px 20px !important;
}
.card-bottom-container {
  height: 20%;
  border-top: 1px solid #dcdcdc;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.address-addCard-container {
  height: 330px;
  border: 1px solid #dcdcdc;
  padding: 20px;
  margin-bottom: 20px;
}
