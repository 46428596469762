.circle-item {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  bottom: 0px;
  font-size: 12px;
  background: orange;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-item-product {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  top: -10px;
  font-size: 12px;
  background: orange;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-item-grid {
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  bottom: 75px;
  font-size: 12px;
  background: orange;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-black {
  color: #444658 !important;
}
.product-img {
  max-height: 200px;
  width: auto;
}
.product-img-border {
  border: 1px solid #dcdcdc;
}
.product-description-container {
  border: 1px solid #dcdcdc;
  padding: 20px;
}
.product-description {
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 245px;
}
.availabilityContainer {
  padding-top: 10px;
  min-width: 100%;
  min-height: 30px;
  display: block;
}
@media (max-width: 320px) {
  .circle-item {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    bottom: 65px;
    font-size: 12px;
    background: orange;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circle-item-simple {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    bottom: 0px;
    font-size: 12px;
    background: orange;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-long-dicount-container {
    position: absolute;
    width: 100% !important;
    height: 90%;
  }
}
