.shipping-method-title {
  font-size: 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  color: #505050;
  font-family: 'Agora';
}
.shipping-method-title-enabled {
  border-bottom: 2px solid #679964;
}
